<template>
  <div style="background: white" id="aa">
    <!-- <HeaderBar /> -->
    <v-sheet class="overflow-hidden">
      <Loading />
      <v-container fluid id="sub-bar">
        <v-row>
          <v-col cols="11">
            <v-btn text @click="showMenu">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
            <div id="lbl-screen-name">登録1</div>
          </v-col>
          <v-col cols="1">
            <v-btn text id="btn-logout" class="menu-btn" @click="logout">
              <img src="../../assets/img/logout_menu_icon.png" />ログアウト</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <!-- 検索項目-->
      <v-container fluid>
        <v-row>
          <v-col cols="12" />
        </v-row>
        <v-row>
          <v-col cols="2">
            <!-- 取引先-->
            <v-row>
              <v-col cols="11">
                <v-autocomplete
                  dense
                  v-model="suppliersSelected"
                  :items="dummy_supplierList"
                  :label="$t('label.lbl_supplier')"
                  :hint="setSrhSupplier()"
                  class="txt-single"
                  persistent-hint
                ></v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-row><span class="require">*</span> </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="2">
            <!-- 入荷元-->
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="arrivalSelected"
                  :items="dummy_arrivalList"
                  :label="$t('label.lbl_arrival')"
                  :hint="setSrhArrival()"
                  class="txt-single"
                  persistent-hint
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <!-- 荷主伝票番号-->
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="txt_slipNo"
                  maxlength="50"
                  :label="$t('label.lbl_slipNo')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  clearable
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <!-- 入荷ステータス-->
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="arrivalStatusSelected"
                  :items="dummy_arrivalStatusList"
                  :label="$t('label.lbl_arrivalStatus')"
                  :hint="setStsArrival()"
                  class="txt-single"
                  persistent-hint
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <!-- 入荷予定日付-->
            <v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  v-model="arrivalScheduleDate"
                  :label="$t('label.lbl_arrivalScheduleDate')"
                  @change="changeDate"
                  :rules="[rules.yyyymmdd]"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-row><span class="require">*</span> </v-row>
              </v-col>
              <v-col cols="5">
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text><v-icon>mdi-calendar</v-icon></v-btn>
                  </template>
                  <v-date-picker
                    v-model="arrivalScheduleDateCal"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
                <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text>
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1">
            <!-- 有償/無償-->
            <v-row>
              <v-col cols="12">
                <v-btn-toggle v-model="isPaid" color="blue darken-2" class="toggle black--text">
                  <v-btn id="btn-pack" class="paid-btn" value="1" small>
                    {{ $t("btn.btn_paid") }}
                  </v-btn>
                  <v-btn id="btn-load" class="paid-btn" value="2" small>
                    {{ $t("btn.btn_free") }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>

          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <!-- 追加ボタン-->
                  <v-btn color="primary" class="api-btn" v-on:click="add">
                    {{ $t("btn.btn_add") }}
                  </v-btn>
                </v-col>
                <v-col cols="5" />
                <v-col cols="1">
                  <!-- 登録ボタン-->
                  <v-btn color="primary" id="btn-pack" class="api-btn" value="1" @click="sendData">
                    {{ $t("btn.btn_insert") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
      <!-- データテーブル（項目） -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          disable-pagination
          disable-sort
          :hide-default-footer="true"
          height="655px"
        >
          <!-- 商品コード/品名-->
          <template v-slot:[`item.productCd`]="{ item }">
            <v-autocomplete
              v-model="item.productCd"
              :items="productList"
              @change="(event) => changeProductNm(event, item)"
              :hint="checkProductCd(item)"
              persistent-hint
              dense
              v-bind:class="{ isD: item.isNew }"
            ></v-autocomplete>
          </template>
          <!-- 入荷予定数-->
          <template v-slot:[`item.productQty`]="{ item, index }">
            <v-text-field
              class="input-number"
              dense
              outlined
              v-model="item.productQty"
              maxlength="10"
              @change="changeQty(item, index)"
              :rules="[rules.isNumber]"
              clear-icon="mdi-close-circle"
              clearable
            />
          </template>
          <!-- DOT-->
          <template v-slot:[`item.lot`]="{ item }">
            <v-text-field
              class="text-box"
              outlined
              dense
              v-model="item.lot"
              maxlength="10"
              clear-icon="mdi-close-circle"
              clearable
            />
          </template>
          <!-- 賞味期限
          <template v-slot:item.expireDate="{ item }">
            <v-text-field
              outlined
              dense
              v-model="item.expireDate"
              maxlength="10"
              :rules="[rules.yyyymmdd]"
              placeholder="yyyy/mm/dd"
              clear-icon="mdi-close-circle"
              clearable
            />
          </template>
           商品付帯-
          <template v-slot:item.productAcc="{ item }">
            <v-text-field
              outlined
              dense
              v-model="item.productAcc"
              maxlength="10"
              clear-icon="mdi-close-circle"
              clearable
            />
          </template>
          -->
          <!-- ケース数-->
          <template v-slot:[`item.caseSu`]="{ item }">
            <v-text-field
              outlined
              dense
              v-model="item.caseSu"
              maxlength="10"
              class="input-number"
              :hint="setHintCase(item)"
              persistent-hint
              :rules="[rules.isNumber]"
              clear-icon="mdi-close-circle"
              clearable
            />
          </template>
          <!-- ポール数-->
          <template v-slot:[`item.ballSu`]="{ item }">
            <v-text-field
              outlined
              dense
              v-model="item.ballSu"
              maxlength="10"
              class="input-number"
              :hint="setHintBall(item)"
              persistent-hint
              :rules="[rules.isNumber]"
              clear-icon="mdi-close-circle"
              clearable
            />
          </template>
          <!-- バラ数-->
          <template v-slot:[`item.baraSu`]="{ item }">
            <v-text-field
              outlined
              dense
              v-model="item.baraSu"
              maxlength="10"
              class="input-number"
              :hint="setHintBara(item)"
              persistent-hint
              :rules="[rules.isNumber]"
              clear-icon="mdi-close-circle"
              clearable
            />
          </template>
          <!-- 付帯情報/ロケーション予約 -->
          <template v-slot:[`item.accInfos`]="{ item }">
            <v-btn small @click="openAccData(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-truncate" style="max-width: 200px" v-bind="attrs" v-on="on">
                  {{ item.accInfos }}
                </div>
              </template>
              <span>{{ item.accInfos }}</span>
            </v-tooltip>
          </template>
          <!-- 削除-->
          <template v-slot:[`item.deleteData`]="{ item }">
            <v-btn small @click="deleteItem(item)" text><v-icon>mdi-delete</v-icon></v-btn>
          </template>
        </v-data-table>
        <!-- 付帯情報/ロケーション予約ポップアップ-->
        <v-dialog v-model="dialogAddProudct" :max-width="800">
          <v-card>
            <v-card-title id="sub-bar">
              <span id="lbl-screen-name">{{ $t("label.lbl_arrivalScheduleRegistDetail") }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      v-model="location"
                      :label="$t('label.lbl_location')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-btn @click="addLocation()" text>
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-h5"
                    >{{ $t("label.lbl_accessoryInfo") }}
                    <v-btn @click="addAccItem" text><v-icon>mdi-plus</v-icon></v-btn></v-col
                  >
                </v-row>
                <!-- 付帯情報入力箇所 -->
                <v-row>
                  <v-data-table
                    hide-default-header
                    :headers="accHeader"
                    :items="accList"
                    disable-filtering
                    disable-pagination
                    disable-sort
                    :hide-default-footer="true"
                  >
                    <template v-slot:[`item.accSelected`]="{ item }">
                      <v-autocomplete
                        v-model="item.accSelected"
                        :items="accKeys"
                        @change="(event) => changeAcc(event, item)"
                        outlined
                        dense
                      ></v-autocomplete>
                      <v-text-field v-show="false" v-model="item.accSelectedNm"></v-text-field>
                    </template>
                    <template v-slot:[`item.accValue`]="{ item }">
                      <v-text-field
                        outlined
                        dense
                        v-model="item.accValue"
                        maxlength="10"
                        clear-icon="mdi-close-circle"
                        clearable
                      />
                    </template>
                    <template v-slot:[`item.delete`]="{ item }">
                      <v-btn small @click="deleteAccItem(item)" text>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="api-btn" @click="close">
                {{ $t("btn.btn_close") }}
              </v-btn>
              <v-btn class="api-btn" @click="save">
                {{ $t("btn.btn_insert") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- 新製品登録ポップアップ-->
        <v-dialog v-model="dialogNewProduct" :max-width="600">
          <v-card>
            <v-card-title id="sub-bar">
              <span id="lbl-screen-name">{{ $t("label.lbl_newProductAdd") }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                      v-model="popNewProductCd"
                      :label="$t('label.lbl_productCode')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                      v-model="popNewProductNm"
                      :label="$t('label.lbl_productCodeName')"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="api-btn" @click="popNewProductClose">
                {{ $t("btn.btn_close") }}
              </v-btn>
              <v-btn class="api-btn" @click="popNewProductSave">
                {{ $t("btn.btn_insert") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
      <!-- 表下ボタン -->
      <v-container>
        <v-row>
          <v-col cols="7" />
          <!--取込取消 
          <v-col cols="5">
            <v-row>
              <v-col cols="10" />
              <v-col cols="2">
                <v-col cols="4" />
                <v-btn color="primary" id="btn-pack" class="api-btn" value="1" @click="sendData">
                  {{ $t("btn.btn_insert") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>-->
        </v-row>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
      />
      <!-- <v-dialog v-model="dialogLocation" :max-width="800">
        <LocationDialog
          :isOpenDetailDialog.sync="dialogLocation"
          v-on:setLocation="saveLocation"
          :locInfo="locInfo"
        />
      </v-dialog> -->
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
//有馬さん対応後、コメント外す
//import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
//有馬さん対応後、コメント外す
//import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";
// import HeaderBar from "@/components/HeaderBar.vue";
export default {
  name: "listAdd3",
  components: {
    // HeaderBar,
    Loading,
    SimpleDialog,
    sideMenu,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    warehouse: { warehouse: "", location: "", productCd: "", qty: "" },
    locInfo: { locProductCd: "", warehouse: "", location: "", productCd: "", qty: 0, init: false },
    // ロケーション登録に渡す製品コード
    locProductCd: "",
    // ロケーションダイアログ
    dialogLocation: false,
    // 新製品登録ダイアログ
    dialogNewProduct: false,
    // 新製品コード
    popNewProductCd: "",
    // 新製品名
    popNewProductNm: "",
    // 一覧index
    editedIndex: -1,
    // 入荷予定登録ダイアログ
    dialogAddProudct: false,
    // 荷主伝票番号
    txt_slipNo: "",
    // 有償/無償
    isPaid: "1",
    // 入荷先
    arrivalSelected: "",
    // 入荷ステータス
    arrivalStatusSelected: "",
    // 取引先
    suppliersSelected: "",
    // メニュー
    openMenu: null,
    // 入荷予定No.
    txt_arrivalScheduleNo: "",
    // 入荷予定日付キャレンダー
    dateMenu: false,
    // 賞味期限日付キャレンダー
    dateExpire: false,
    // 入荷予定日付
    arrivalScheduleDateCal: "",
    expireDateCal: "",
    arrivalScheduleDate: "",
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    productListIdx: -1,
    // 入荷予定品目一覧ヘッダ
    headerItems: [
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "13%",
        align: "center",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_productQty"),
        value: "productQty",
        width: "9%",
        align: "center",
        sortable: false,
      },
      { text: i18n.tc("label.lbl_lot"), value: "lot", width: "13%", align: "center" },
      //{ text: "賞味期限", value: "expireDate", width: "10%", align: "center" },
      //{
      //  text: i18n.tc("label.lbl_productAccessory"),
      //  value: "productAcc",
      //  width: "12%",
      //  align: "center",
      //  sortable: false,
      //},

      { text: i18n.tc("label.lbl_caseSu"), value: "caseSu", width: "9%", align: "center" },
      { text: i18n.tc("label.lbl_ballSu"), value: "ballSu", width: "9%", align: "center" },
      { text: i18n.tc("label.lbl_baraSu"), value: "baraSu", width: "9%", align: "center" },

      {
        text: i18n.tc("label.lbl_arrivalScheduleRegistDetail"),
        value: "accInfos",
        width: "5%",
        align: "center",
      },
      { text: i18n.tc("btn.btn_delete"), value: "deleteData", width: "5%", align: "center" },
    ],
    inputList: [],

    dummy_supplierList: [
      { text: "", value: "" },
      {
        text: "supplier1（取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先1）",
        value: "supplier1",
        name: "取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先取引先1",
      },
      { text: "supplier2（取引先2）", value: "supplier2", name: "取引先2" },
      { text: "supplier3（取引先3）", value: "supplier3", name: "取引先3" },
      { text: "supplier4（取引先4）", value: "supplier4", name: "取引先4" },
      { text: "supplier5（取引先5）", value: "supplier5", name: "取引先5" },
    ],
    dummy_arrivalList: [
      { text: "", value: "" },
      { text: "arrival1（入荷先1）", value: "arrival1", name: "入荷先1" },
      { text: "arrival2（入荷先2）", value: "arrival2", name: "入荷先2" },
      { text: "arrival3（入荷先3）", value: "arrival3", name: "入荷先3" },
      { text: "arrival4（入荷先4）", value: "arrival4", name: "入荷先4" },
      { text: "arrival5（入荷先5）", value: "arrival5", name: "入荷先5" },
    ],
    dummy_arrivalStatusList: [
      { text: "", value: "" },
      { text: "status1（ステータス1）", value: "arrival1", name: "入荷" },
      { text: "status2（ステータス2）", value: "arrival2", name: "良品" },
    ],
    defaultItem: {
      productNm: "",
      productCd: "",
      productAcc: "",
      productQty: "",
      caseSu: "",
      ballSu: "",
      baraSu: "",
      lot: "",
      warehouse: { warehouse: "", location: "", productCd: "", qty: "" },
      accInfo: [],
      accInfos: "",
      isNew: false,
    },
    productList: [
      { text: "", value: "", isNew: false },
      { text: i18n.tc("label.lbl_newProductAdd"), value: "product0", name: "仮登録", isNew: true },
      { text: "product1（製品1）", value: "product1", name: "製品1", isNew: false },
      { text: "product2（製品2）", value: "product2", name: "製品2", isNew: false },
      { text: "product3（製品3）", value: "product3", name: "製品3", isNew: false },
      { text: "product4（製品4）", value: "product4", name: "製品4", isNew: false },
    ],
    accHeader: [
      { text: "キー", value: "accSelected", align: "center", width: "20%" },
      { text: "バリュー", value: "accValue", align: "center", width: "70%" },
      { text: "削除", value: "delete", align: "center", width: "10%" },
    ],
    accKeyMst: [],
    accList: [],
    accKeys: [],
    newProductInfo: {
      text: "新商品コード",
      value: "newProductCd",
      name: "",
    },
    location: "",
    rules: {
      yyyymmdd: (value) =>
        dateTimeHelper.validDate(commonUtil.zen2han(value), "/") || i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNumber"),
    },
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定
     */
    init() {
      this.arrivalSelected = "";
      this.arrivalStatusSelected = "";
      this.suppliersSelected = "";
      this.txt_arrivalScheduleNo = "";
      this.txt_slipNo = "";
      this.arrivalScheduleDateCal = this.dateCalc(dateTimeHelper.convertJstDateTime(new Date()));
    },

    /**
     * 入荷予定登録画面：メニューダイアログ処理
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 入荷予定登録画面：入荷予定日付を－１日
     */
    prevDate() {
      let date = new Date(this.arrivalScheduleDateCal);
      this.arrivalScheduleDateCal = this.dateCalc(date.setDate(date.getDate() - 1));
    },

    /**
     * 入荷予定登録画面：入荷予定日付を＋１日
     */
    nextDate() {
      let date = new Date(this.arrivalScheduleDateCal);
      this.arrivalScheduleDateCal = this.dateCalc(date.setDate(date.getDate() + 1));
    },

    /**
     * 入荷予定登録画面：与えられた日付から年月日を文字列で返却処理
     */
    dateCalc(date) {
      const dt = new Date(date);
      return dt.toISOString().substr(0, 10);
    },

    /**
     * 入荷予定登録画面：yyyy/MM/ddに変換処理（カレンダコンポーネントの日付はyyyy-MM-ddのため）
     */
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },

    /**
     * 入荷予定登録画面：付帯情報ツールチップ表示処理
     */
    setItemToList() {
      // 付帯情報詳細処理
      const accInfos = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfos.push(this.accList[i].accSelectedNm + ":" + this.accList[i].accValue);
      }
      return accInfos.join("\n");
    },

    /**
     * 入荷予定登録画面：行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.inputList.splice(this.editedIndex, 1);
    },

    /**
     * 入荷予定登録画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.dummy_supplierList.length; i++) {
        if (this.dummy_supplierList[i].value == this.suppliersSelected) {
          return this.dummy_supplierList[i].name;
        }
      }
    },

    /**
     * 入荷予定登録画面：入荷元コードリストボックス取得処理
     */
    setSrhArrival() {
      for (var i = 0; i < this.dummy_arrivalList.length; i++) {
        if (this.dummy_arrivalList[i].value == this.arrivalSelected) {
          return this.dummy_arrivalList[i].name;
        }
      }
    },

    /**
     * 入荷予定登録画面：入荷ステータスリストボックス取得処理
     */
    setStsArrival() {
      for (var i = 0; i < this.dummy_arrivalStatusList.length; i++) {
        if (this.dummy_arrivalStatusList[i].value == this.arrivalStatusSelected) {
          return this.dummy_arrivalStatusList[i].name;
        }
      }
    },
    /**
     * 入荷予定登録画面：商品コードリストボックス取得処理
     */
    checkProductCd(value) {
      for (var i = 0; i < this.productList.length; i++) {
        if (this.productList[i].value == value.productCd) {
          return this.productList[i].name;
        }
      }
    },

    /**
     * 入荷予定登録画面：ケース数計算処理
     */
    setHintCase(value) {
      var proQty = commonUtil.zen2han(value.productQty).replaceAll(",", "");
      if (!isNaN(Number(proQty))) {
        return commonUtil.formatToCurrency(Math.ceil(proQty / 20));
      } else {
        return "";
      }
    },

    /**
     * 入荷予定登録画面：ボール数計算処理
     */
    setHintBall(value) {
      var proQty = commonUtil.zen2han(value.productQty).replaceAll(",", "");
      if (!isNaN(Number(proQty))) {
        return commonUtil.formatToCurrency(Math.ceil(proQty / 10));
      } else {
        return "";
      }
    },

    /**
     * 入荷予定登録画面：バラ数計算処理
     */
    setHintBara(value) {
      var proQty = commonUtil.zen2han(value.productQty).replaceAll(",", "");
      if (!isNaN(Number(proQty))) {
        return commonUtil.formatToCurrency(Math.ceil(proQty / 3));
      } else {
        return "";
      }
    },

    /**
     * 入荷予定登録画面：登録処理
     */
    sendData() {
      this.infoDialog.message = "保存しました";
      this.infoDialog.title = "結果";
      this.infoDialog.isOpen = true;
    },

    /**
     * 入荷予定登録画面：行追加処理
     */
    add() {
      this.inputList.push(Object.assign({}, this.defaultItem));
    },

    /**
     * 入荷予定登録画面：入荷予定数、入力カンマ処理
     */
    changeQty(val, index) {
      var proQty = commonUtil.zen2han(val.productQty).replaceAll(",", "");
      if (!isNaN(Number(proQty))) {
        this.inputList[index].caseSu = commonUtil.formatToCurrency(Math.ceil(proQty / 20));
        this.inputList[index].ballSu = commonUtil.formatToCurrency(Math.ceil(proQty / 10));
        this.inputList[index].baraSu = commonUtil.formatToCurrency(Math.ceil(proQty / 3));
        this.inputList[index].productQty = commonUtil.formatToCurrency(Number(proQty));
      } else {
        this.inputList[index].caseSu = "0";
        this.inputList[index].ballSu = "0";
        this.inputList[index].baraSu = "0";
      }
    },

    /**
     * 入荷予定登録画面：入力カンマ処理
     */
    changeNum(val, index) {
      var proQty = commonUtil.zen2han(val.productQty).replaceAll(",", "");
      if (!isNaN(Number(proQty))) {
        this.inputList[index].caseSu = commonUtil.formatToCurrency(Math.ceil(proQty / 20));
        this.inputList[index].ballSu = commonUtil.formatToCurrency(Math.ceil(proQty / 10));
        this.inputList[index].baraSu = commonUtil.formatToCurrency(Math.ceil(proQty / 3));
        this.inputList[index].productQty = commonUtil.formatToCurrency(Number(proQty));
      } else {
        this.inputList[index].caseSu = "0";
        this.inputList[index].ballSu = "0";
        this.inputList[index].baraSu = "0";
      }
    },

    /**
     * 入荷予定登録画面：日付入力処理
     */
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "-" + inputVal.substr(4, 2) + "-" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "-" + inputVal.substr(5, 2) + "-" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "-")) {
        this.arrivalScheduleDateCal = strDt;
      }
    },

    /**
     * 入荷予定登録画面：閉じる処理
     */
    popNewProductClose() {
      this.dialogNewProduct = false;
    },

    /**
     * 付帯情報/ロケーション予約POPUP：閉じる処理
     */
    close() {
      this.dialogAddProudct = false;
      this.editedIndex = -1;
    },

    /**
     * 付帯情報/ロケーション予約POPUP：付帯情報/ロケーション予約POPUP処理
     */
    openAccData(item) {
      this.editedIndex = this.inputList.indexOf(item);
      // ロケーション情報存在有無チェック
      if (this.inputList[this.editedIndex].warehouse.location != "") {
        this.location = this.inputList[this.editedIndex].warehouse.location;
        this.warehouse = this.inputList[this.editedIndex].warehouse;
      } else {
        this.location = "";
      }
      // 付帯情報存在有無チェック
      if (this.inputList[this.editedIndex].accInfo.length != 0) {
        this.accList = this.inputList[this.editedIndex].accInfo;
      } else {
        this.getAccTarget(item);
        this.getAccMst();
        this.accList = [];
        for (var i = 0; i < this.accKeys.length; i++) {
          if (this.accKeys[i].required == "1") {
            this.accList.push({
              accSelected: this.accKeys[i].value,
              accValue: "",
              accSelectedNm: this.accKeys[i].text,
            });
          }
        }
      }
      this.dialogAddProudct = true;
    },

    /**
     * 付帯情報/ロケーション予約POPUP：行削除処理
     */
    deleteAccItem(item) {
      this.editedIndex = this.accList.indexOf(item);
      this.accList.splice(this.editedIndex, 1);
    },

    /**
     * 付帯情報/ロケーション予約POPUP：行追加処理
     */
    addAccItem() {
      this.accList.push({ accSelected: "", accValue: "" });
    },

    /**
     * 付帯情報/ロケーション予約POPUP：登録処理
     */
    save() {
      var accInfos = this.setItemToList();
      this.warehouse.location = this.location;
      this.inputList[this.editedIndex].accInfos = accInfos;
      this.inputList[this.editedIndex].accInfo = this.accList;
      this.inputList[this.editedIndex].warehouse = this.warehouse;
      this.close();
    },

    /**
     * 付帯情報/ロケーション予約POPUP：付帯情報リストボックス取得処理
     */
    changeAcc(value, item) {
      for (var i = 0; i < this.accKeys.length; i++) {
        if (this.accKeys[i].value == value) {
          item.accSelectedNm = this.accKeys[i].text;
          break;
        }
      }
    },

    /**
     * 付帯情報/ロケーション予約POPUP：商品に紐づく付帯情報取得処理
     */
    getAccTarget(item) {
      this.accKeys = [];

      if (item.productCd == "product1") {
        this.accKeys.push({ text: "色", value: "color", required: "1" });
      } else if (item.productCd == "product2") {
        this.accKeys.push({ text: "色", value: "color", required: "1" });
        this.accKeys.push({ text: "サイズ", value: "size", required: "1" });
      } else if (item.productCd == "product3") {
        this.accKeys.push({ text: "色", value: "color", required: "1" });
        this.accKeys.push({ text: "サイズ", value: "size", required: "1" });
        this.accKeys.push({ text: "製造日付", value: "makeDt", required: "1" });
      } else if (item.productCd == "product4") {
        this.accKeys.push({ text: "色", value: "color", required: "1" });
        this.accKeys.push({ text: "サイズ", value: "size", required: "1" });
        this.accKeys.push({ text: "製造日付", value: "makeDt", required: "1" });
        this.accKeys.push({ text: "メーカー", value: "maker", required: "1" });
      }
    },

    /**
     * 付帯情報/ロケーション予約POPUP：マスタから取得する付帯情報
     */
    getAccMst() {
      this.accKeys.push({ text: "材料", value: "material", required: "0" });
      this.accKeys.push({ text: "備考", value: "bigo", required: "0" });
    },
    /**
     * ロケーション予約画面処理
     */
    addLocation() {
      if (this.inputList[this.editedIndex].warehouse == {}) {
        this.locInfo.warehouse = "";
        this.locInfo.location = "";
        this.locInfo.productCd = "";
        this.locInfo.qty = 0;
        this.locInfo.init = true;
      } else {
        this.locInfo.warehouse = this.inputList[this.editedIndex].warehouse.warehouse;
        this.locInfo.location = this.inputList[this.editedIndex].warehouse.location;
        this.locInfo.productCd = this.inputList[this.editedIndex].warehouse.productCd;
        this.locInfo.qty = this.inputList[this.editedIndex].warehouse.qty;
        this.locInfo.init = false;
      }
      this.locInfo.locProductCd = this.inputList[this.editedIndex].productCd;
      this.dialogLocation = true;
    },

    /**
     * 新製品登録POPUP：新製品登録処理
     */
    changeProductNm(value, item) {
      if (value == "product0") {
        this.dialogNewProduct = true;
      } else {
        for (var i = 0; i < this.productList.length; i++) {
          if (this.productList[i].value == value) {
            item.productNm = this.productList[i].name;
            item.isNew = this.productList[i].isNew;
            break;
          }
        }
      }
    },

    /**
     * 新製品登録POPUP：登録処理*********************新製品登録時、プルダウンに設定されるように修正中
     */
    popNewProductSave() {
      this.productList.push({
        text: this.popNewProductNm + "（" + this.popNewProductCd + "）",
        value: this.popNewProductCd,
        name: this.popNewProductNm,
        isNew: true,
      });
      this.dialogNewProduct = false;
      this.newProductInfo.push({ value: this.popNewProductCd, name: this.popNewProductNm });
      this.inputList[this.editedIndex].isNew = true;
      this.inputList[this.editedIndex].productCd = this.newProductInfo;
    },

    /**
     * ロケーション予約画面：登録後処理
     */
    saveLocation(data) {
      this.warehouse = data;
      this.location = data.location;
      this.dialogLocation = false;
    },
  },

  computed: {
    expireDate() {
      return this.formatDate(this.expireDateCal);
    },
  },
  watch: {
    arrivalScheduleDateCal(val) {
      this.arrivalScheduleDate = this.formatDate(val);
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}

//新製品登録時、色変更
.isD {
  background-color: $color-warning !important;
  //background-color: $color-error !important;
}
</style>
